import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import {
    MatDialog,
    MatDialogConfig,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
    MatTable,
    MatTableDataSource,
    MatTableModule,
} from '@angular/material/table';
import { Observable, Subscription, timer } from 'rxjs';
import { ReportingServicesLog } from '../../../model/ReportingServicesLog';
import {
    RosterJobStatusHeader,
    RosterJobStatusDetail,
} from '../../../model/RosterPublicationJobStatus';
import { AuditlogService } from '../../../services/auditlog.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

dayjs().format();
dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
    selector: 'app-roster-logs',
    templateUrl: './roster-logs.component.html',
    styleUrls: ['./roster-logs.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
    ],
})
export class RosterLogsComponent implements OnInit {
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChildren('innerTables') innerTables:
        | QueryList<MatTable<RosterJobStatusDetail>>
        | undefined;
    @ViewChildren('innerSort') innerSort!: QueryList<MatSort>;
    reportingServicesLog: Array<ReportingServicesLog> = [];
    dataSource: MatTableDataSource<ReportingServicesLog>;
    dataSourceStatus: MatTableDataSource<RosterJobStatusHeader>;
    rosterPublicationJobStatusList: Array<RosterJobStatusHeader> = [];
    itdoesntmatter: Array<RosterJobStatusHeader> = [];
    showLogsTable: boolean = false;
    expandedElement: RosterJobStatusHeader | null | undefined;

    displayedColumns: string[] = ['timestamp', 'message', 'exception'];
    innerDisplayedColumns = [
        'reportName',
        'jobStartedDate',
        'jobCompleteDate',
        'rowCount',
        'reportConfig',
        'getEAPData',
        'excelGeneration',
        'uploadExcelToBlob',
        'createUploadRosterToSAPCPI',
        'errorMessage',
    ];
    displayedColumnsStatus: string[] = [
        'jobStartedDate',
        'jobCompleteDate',
        'actionsColumn',
    ];
    dateformcontrol = new FormControl(new Date());
    selecteddate: string;

    constructor(
        private auditlogservice: AuditlogService,

        private cd: ChangeDetectorRef,
        public dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource(this.reportingServicesLog);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSourceStatus = new MatTableDataSource(
            this.rosterPublicationJobStatusList
        );
        this.dataSourceStatus.sort = this.sort;
        this.dataSourceStatus.paginator = this.paginator;
        this.selecteddate = formatDate(Date.now(), 'MM-dd-yyyy', 'en-US');
    }

    ngOnInit(): void {
        this.rosterPublicationJobStatusList.forEach(item => {
            if (
                item.rosterJobStatusDetails != undefined &&
                item.rosterJobStatusDetails &&
                Array.isArray(item.rosterJobStatusDetails) &&
                item.rosterJobStatusDetails.length
            ) {
                this.rosterPublicationJobStatusList = [
                    ...this.rosterPublicationJobStatusList,
                    {
                        ...item,
                        rosterJobStatusDetails: new MatTableDataSource(
                            item.rosterJobStatusDetails
                        ),
                    },
                ];
            } else {
                this.rosterPublicationJobStatusList = [
                    ...this.rosterPublicationJobStatusList,
                    item,
                ];
            }
        });
    }
    loadRosterPublicationStatusByDate(selecteddate: any) {
        this.rosterPublicationJobStatusList = [];

        this.auditlogservice
            .loadRosterPublicationStatusByDate(selecteddate)
            .subscribe(result => {
                result.forEach(item => {
                    if (
                        item.rosterJobStatusDetails != undefined &&
                        item.rosterJobStatusDetails &&
                        Array.isArray(item.rosterJobStatusDetails) &&
                        item.rosterJobStatusDetails.length
                    ) {
                        this.rosterPublicationJobStatusList = [
                            ...this.rosterPublicationJobStatusList,
                            {
                                ...item,
                                rosterJobStatusDetails: new MatTableDataSource(
                                    item.rosterJobStatusDetails
                                ),
                            },
                        ];
                    } else {
                        this.rosterPublicationJobStatusList = [
                            ...this.rosterPublicationJobStatusList,
                            item,
                        ];
                    }
                });
                this.dataSourceStatus = new MatTableDataSource(
                    this.rosterPublicationJobStatusList
                );
                this.dataSourceStatus.sort = this.sort;
                this.dataSourceStatus.paginator = this.paginator;
            });
    }

    formatDate(date: Date) {
        return dayjs
            .utc(date.toString())
            .tz('America/New_York')
            .format('M/D/YY h:mm:ss A');
    }

    loadRosterPublicationLogListByDate(selecteddate: any) {
        this.auditlogservice
            .loadRosterPublicationLogListByDate(selecteddate)
            .subscribe(result => {
                this.reportingServicesLog = result;
                this.dataSource = new MatTableDataSource(
                    this.reportingServicesLog
                );
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
            });
    }

    showLogs() {
        this.loadRosterPublicationLogListByDate(this.selecteddate);
        this.showLogsTable = true;
    }

    ShowLoadStatus() {
        this.showLogsTable = false;
        this.loadRosterPublicationStatusByDate(this.selecteddate);
    }

    OnDateChange(selecteddate: any) {
        this.selecteddate = formatDate(selecteddate, 'MM-dd-yyyy', 'en-US');
        if (this.showLogsTable) {
            this.loadRosterPublicationLogListByDate(this.selecteddate);
        } else {
            this.loadRosterPublicationStatusByDate(this.selecteddate);
        }
    }

    openDialogReportingServicesLogMessage(row: ReportingServicesLog): void {
        const userDialogConfig = new MatDialogConfig();
        userDialogConfig.disableClose = false;
        userDialogConfig.autoFocus = true;
        console.log(row);
        userDialogConfig.data = {
            data: {
                message: row.message,
                rowid: 0,
                source: 'logviewer',
                buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel',
                },
            },
        };
        this.dialog
            .open(ConfirmDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe();
    }

    openDialogReportingServicesLogError(row: ReportingServicesLog): void {
        const userDialogConfig = new MatDialogConfig();
        userDialogConfig.disableClose = false;
        userDialogConfig.autoFocus = true;
        console.log(row);
        userDialogConfig.data = {
            data: {
                message: row.exception,
                rowid: 0,
                source: 'logviewer',
                buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel',
                },
            },
        };
        this.dialog
            .open(ConfirmDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe();
    }

    openDialog(row: RosterJobStatusDetail): void {
        const userDialogConfig = new MatDialogConfig();
        userDialogConfig.disableClose = false;
        userDialogConfig.autoFocus = true;
        console.log(row);
        userDialogConfig.data = {
            data: {
                message: row.errorMessage,
                rowid: 0,
                source: 'logviewer',
                buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel',
                },
            },
        };
        this.dialog
            .open(ConfirmDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe();
    }

    toggleRow(element: RosterJobStatusHeader) {
        element.rosterJobStatusDetails &&
        (
            element.rosterJobStatusDetails as MatTableDataSource<RosterJobStatusDetail>
        ).data?.length
            ? (this.expandedElement =
                  this.expandedElement === element ? null : element)
            : null;
        this.cd.detectChanges();

        if (this.innerTables != undefined) {
            this.innerTables.forEach(
                (table, index) =>
                    ((
                        table.dataSource as MatTableDataSource<RosterJobStatusDetail>
                    ).sort = this.innerSort.toArray()[index])
            );
        }
    }
}
