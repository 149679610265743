import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { timeStamp } from 'console';
import { catchError, Observable, of, throwError } from 'rxjs';
import { clientSession } from '../../../../client-session';
import { AgreementTypeCode } from '../../../../model/AgreementTypeCode';
import { LevelCode } from '../../../../model/LevelCode';
import { PeriodProfileCode } from '../../../../model/PeriodProfileCode';
import { QuarterCode } from '../../../../model/QuarterCode';
import {
    AgreementSort,
    DetailedAgreement,
    SortLock,
} from '../../../../model/Sort';
import { SystemConfiguration } from '../../../../model/SystemConfiguration';
import { User } from '../../../../model/User';
import { CanComponentDeactivate } from '../../../../services/can-deactivate-guard.service';
import { ScorecardService } from '../../../../services/scorecard.service';
import { TableUtil } from '../../../../utilities/TableUtil';
import { SortCopydialogComponent } from '../sort-copydialog/sort-copydialog.component';
import { SortadditemComponent } from '../sortadditem/sortadditem.component';
import { SortmoveitemComponent } from '../sortmoveitem/sortmoveitem.component';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-sort',
    templateUrl: './sort.component.html',
    styleUrls: ['./sort.component.scss'],
})
export class SortComponent implements OnInit, CanComponentDeactivate {
    aggsortION: Array<AgreementSort> = [];
    aggsortIPN: Array<AgreementSort> = [];
    aggsortSAN: Array<AgreementSort> = [];
    aggsort: Array<AgreementSort> = [];
    agreementList: Array<DetailedAgreement> | undefined = [];
    saved: boolean = true;
    dataSource: MatTableDataSource<AgreementSort>;
    selection = new SelectionModel<AgreementSort>(true, []);
    public currentDate = new Date();
    selectedVal: string;
    selectedValOral: string;
    submitted: boolean = false;
    dbvalue: boolean = true;
    public quarterCodeFormControl: FormControl;
    quarterCode: Array<QuarterCode> = [];
    selectedQuarterCode: QuarterCode = {} as QuarterCode;
    displayedColumns: string[] = [
        'select',
        'agreementId',
        'startDate',
        'endDate',
        'agreementName',
        'agreementTypeDescription',
        'periodProfileDescription',
        'manufacturer',
        'drug',
        'levelDescription',
        'gpo',
        'route',
        'hidden',
        'sortPosition',
        'actionsColumn',
    ];
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    lockedButtonClass: string = 'buttonstyling';
    sortLockButtonName: string = 'Locked';
    public systemConfiguration?: SystemConfiguration[];
    locked: boolean = false;
    lockedByUser: boolean = false;

    constructor(
        private dialog: MatDialog,
        private scorecardservice: ScorecardService,
        private snackBar: MatSnackBar,
        private http: HttpClient,
        private service: ScorecardService
    ) {
        this.selectedVal = 'ION';
        this.selectedValOral = 'All';
        this.dataSource = new MatTableDataSource(this.aggsortION);
        this.quarterCodeFormControl = new FormControl('');
    }

    loadQuarterCode() {
        this.service.loadQuarterCode().subscribe(result => {
            this.quarterCode = result.sort((a, b) =>
                a.sortPosition < b.sortPosition ? -1 : 1
            );
            const currentquarter = result.find(
                obj => obj.currentQuarter === true
            );
            if (currentquarter != null) {
                this.quarterCodeFormControl.setValue(currentquarter.quarter);
                this.selectedQuarterCode = currentquarter;
            }

            this.loadSortList();
        });
    }

    //Handles the toggle of ION,IPN,SAN buttons and loads the corresponding agreements
    onValChange(val: string) {
        //debugger;
        this.selectedVal = val;
        this.selectedValOral = 'All';
        // console.log(this.selectedVal);
        if (this.selectedVal == 'ION') {
            // this.aggsortION = saved.data.SelectedAgreements;
            this.dataSource = new MatTableDataSource(this.aggsortION);
        } else if (this.selectedVal == 'IPN') {
            // this.aggsortIPN = saved.data.SelectedAgreements;
            this.dataSource = new MatTableDataSource(this.aggsortIPN);
        } else if (this.selectedVal == 'SAN') {
            //this.aggsortSAN = saved.data.SelectedAgreements;
            this.dataSource = new MatTableDataSource(this.aggsortSAN);
        }
    }

    //Handles the toggle of Oral,Non-Oral buttons and loads the corresponding agreements
    onValChangeOral(val: string) {
        this.selectedValOral = val;
        if (
            this.selectedValOral == 'Oral' ||
            this.selectedValOral == 'Non-Oral'
        ) {
            this.dbvalue = true;
        } else {
            this.dbvalue = false;
        }
        this.ReOrderSortPosition();
        if (this.selectedValOral == 'Oral') {
            if (this.selectedVal == 'ION') {
                this.dataSource = new MatTableDataSource(
                    this.aggsortION.filter(df => df.route == 'PO')
                );
            } else if (this.selectedVal == 'IPN') {
                this.dataSource = new MatTableDataSource(
                    this.aggsortIPN.filter(df => df.route == 'PO')
                );
            } else if (this.selectedVal == 'SAN') {
                this.dataSource = new MatTableDataSource(
                    this.aggsortSAN.filter(df => df.route == 'PO')
                );
            }
        } else if (this.selectedValOral == 'Non-Oral') {
            if (this.selectedVal == 'ION') {
                this.dataSource = new MatTableDataSource(
                    this.aggsortION.filter(df => df.route !== 'PO')
                );
            } else if (this.selectedVal == 'IPN') {
                this.dataSource = new MatTableDataSource(
                    this.aggsortIPN.filter(df => df.route !== 'PO')
                );
            } else if (this.selectedVal == 'SAN') {
                this.dataSource = new MatTableDataSource(
                    this.aggsortSAN.filter(df => df.route !== 'PO')
                );
            }
        } else {
            if (this.selectedVal == 'ION') {
                this.dataSource = new MatTableDataSource(this.aggsortION);
            } else if (this.selectedVal == 'IPN') {
                this.dataSource = new MatTableDataSource(this.aggsortIPN);
            } else if (this.selectedVal == 'SAN') {
                this.dataSource = new MatTableDataSource(this.aggsortSAN);
            }
        }
    }

    ngOnInit(): void {
        this.loadQuarterCode();
        this.loadAgreementList();
        this.checksortlock();
    }
    //Opens the Copy sort dialog
    copysort() {
        const userDialogConfig = new MatDialogConfig();

        userDialogConfig.disableClose = true;
        userDialogConfig.autoFocus = true;
        //userDialogConfig.data = this.selection.selected; // this.moveitemlist;
        userDialogConfig.width = '50%';
        userDialogConfig.height = '45%';
        this.dialog
            .open(SortCopydialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                this.loadSortList();
            });
    }

    @HostListener('window:beforeunload', ['$event'])
    public onBeforeUnload(event: BeforeUnloadEvent): void {
        this.isAllowedNavigation(true).subscribe(isAllowedNavigation => {
            if (event && !isAllowedNavigation) {
                event.preventDefault();
                event.returnValue = false;
            }
        });
    }

    canDeactivate(): boolean | Observable<boolean> {
        if (this.lockedByUser == true) {
            return window.confirm('Please Unlock before leaving');
        }
        // provide component specific logic to decide if component can or can't be deactivated
        return this.isAllowedNavigation();
    }

    private isAllowedNavigation(
        beforeunloadEvent = false
    ): Observable<boolean> {
        if (!this.saved || beforeunloadEvent) {
            const result = window.confirm(
                'There are unsaved changes! Are you sure?'
            );
            return of(result);
        }
        return of(true);
    }

    //Opens the Move item dialog for moving the agreements to a selected position
    moveitem() {
        this.dbvalue = false;

        if (
            this.selection.isSelected == undefined ||
            this.selection.selected.length == 0
        ) {
            this.openSnackBar('Please select an Agreement');
            return;
        }
        const userDialogConfig = new MatDialogConfig();

        userDialogConfig.disableClose = true;
        userDialogConfig.autoFocus = true;
        userDialogConfig.data = this.selection.selected; // this.moveitemlist;
        userDialogConfig.width = '50%';
        userDialogConfig.height = '45%';
        this.dialog
            .open(SortmoveitemComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                if (saved) {
                    this.moveSelectedAgreements(saved);
                    this.saved = false;
                }
            });
    }

    checksortlock() {
        this.scorecardservice.loadSortLock().subscribe(result => {
            if (result == undefined) {
                this.locked = false;
                this.lockedByUser = false;
                this.lockedButtonClass = 'buttonstyling';
                this.sortLockButtonName = 'Lock';
            } else if (result.id == this.getLoginUserId()) {
                this.locked = false;
                this.lockedByUser = true;
                this.lockedButtonClass = 'lockedbuttonstyling';
                this.sortLockButtonName = 'Locked';
            } else {
                this.locked = true;
                this.lockedByUser = false;
                this.lockedButtonClass = 'lockedbuttonstyling';
                this.sortLockButtonName = 'Locked';

                let lockedUser: string = '';
                this.service
                    .getUserbyAzureUsername(result.azureUsername)
                    .subscribe(user => {
                        lockedUser = user.firstName + ' ' + user.lastName;
                        this.openSnackBar(
                            'Sort is currently locked by user ' +
                                lockedUser +
                                '. Only they can unlock Sort'
                        );
                    });
            }
        });
    }

    togglelock() {
        // check if lock exists

        let requestLockUserId: SortLock = {
            id: this.getLoginUserId(),
            azureUsername: this.getLoginUserAzureUserName(),
            timeStamp: new Date(),
        };
        let lockedUser: string | undefined, localTimestamp: string;
        this.scorecardservice
            .setSortLock(requestLockUserId)
            .subscribe(result => {
                if (result.id != undefined) {
                    // api call only returns SortLock if there is an already existing lock that does not belong to user
                    this.service
                        .getUserbyAzureUsername(result.azureUsername)
                        .subscribe(user => {
                            lockedUser = user.firstName + ' ' + user.lastName;
                            localTimestamp = formatDate(
                                result.timeStamp,
                                'MMM d, y, h:mm:ss a',
                                'en-US'
                            );
                            this.openSnackBar(
                                lockedUser + ' locked Sort at ' + localTimestamp
                            );
                        });
                } else {
                    this.checksortlock();
                    this.loadAgreementList();
                }
            });
    }

    getLoginUserFullName() {
        if (
            clientSession.linkUser?.firstName == undefined &&
            clientSession.linkUser?.lastName == undefined
        ) {
            return '';
        } else {
            return (
                clientSession.linkUser?.firstName +
                ' ' +
                clientSession.linkUser?.lastName
            );
        }
    }

    //Moves the selected agreements to a given position
    moveSelectedAgreements(saved: any) {
        this.selectedValOral = 'All';
        var postion: number = -1;
        if (
            saved.data != undefined &&
            saved.data.Position != undefined &&
            saved.data.Position != ''
        ) {
            //console.log(saved.data.Position);
            postion = saved.data.Position;
        }

        if (
            this.selection != undefined &&
            this.selection.selected != undefined &&
            this.selection.selected.length > 0
        ) {
            for (var i = 0; i < this.selection.selected.length; i++) {
                if (this.selectedVal == 'ION') {
                    var index = this.aggsortION?.findIndex(
                        row =>
                            parseInt(row.agreementId) ===
                                parseInt(
                                    this.selection.selected[i].agreementId
                                ) &&
                            row.agreementType.trim().toLowerCase() ===
                                this.selection.selected[i].agreementType
                                    .trim()
                                    .toLowerCase() &&
                            row.agreementName.trim().toLowerCase() ===
                                this.selection.selected[i].agreementName
                                    .trim()
                                    .toLowerCase() &&
                            row.drug.trim().toLowerCase() ===
                                this.selection.selected[i].drug
                                    .trim()
                                    .toLowerCase() &&
                            row.level.trim().toLowerCase() ===
                                this.selection.selected[i].level
                                    .trim()
                                    .toLowerCase()
                    );
                    if (index > -1) {
                        this.aggsortION.splice(index, 1);
                    }
                } else if (this.selectedVal == 'IPN') {
                    var index = this.aggsortIPN?.findIndex(
                        row =>
                            parseInt(row.agreementId) ===
                                parseInt(
                                    this.selection.selected[i].agreementId
                                ) &&
                            row.agreementType.trim().toLowerCase() ===
                                this.selection.selected[i].agreementType
                                    .trim()
                                    .toLowerCase() &&
                            row.agreementName.trim().toLowerCase() ===
                                this.selection.selected[i].agreementName
                                    .trim()
                                    .toLowerCase() &&
                            row.drug.trim().toLowerCase() ===
                                this.selection.selected[i].drug
                                    .trim()
                                    .toLowerCase() &&
                            row.level.trim().toLowerCase() ===
                                this.selection.selected[i].level
                                    .trim()
                                    .toLowerCase()
                    );
                    if (index > -1) {
                        this.aggsortIPN.splice(index, 1);
                    }
                } else if (this.selectedVal == 'SAN') {
                    var index = this.aggsortSAN?.findIndex(
                        row =>
                            parseInt(row.agreementId) ===
                                parseInt(
                                    this.selection.selected[i].agreementId
                                ) &&
                            row.agreementType.trim().toLowerCase() ===
                                this.selection.selected[i].agreementType
                                    .trim()
                                    .toLowerCase() &&
                            row.agreementName.trim().toLowerCase() ===
                                this.selection.selected[i].agreementName
                                    .trim()
                                    .toLowerCase() &&
                            row.drug.trim().toLowerCase() ===
                                this.selection.selected[i].drug
                                    .trim()
                                    .toLowerCase() &&
                            row.level.trim().toLowerCase() ===
                                this.selection.selected[i].level
                                    .trim()
                                    .toLowerCase()
                    );
                    if (index > -1) {
                        this.aggsortSAN.splice(index, 1);
                    }
                }
            }
            for (var i = 0; i < this.selection.selected.length; i++) {
                if (this.selectedVal == 'ION') {
                    if (postion > this.aggsortION.length || postion == -1) {
                        postion = this.aggsortION.length + 1;
                    }
                    this.aggsortION.splice(
                        postion - 1,
                        0,
                        this.selection.selected[i]
                    );
                    postion = +postion + 1;
                }
                if (this.selectedVal == 'IPN') {
                    if (postion > this.aggsortIPN.length || postion == -1) {
                        postion = this.aggsortIPN.length + 1;
                    }

                    this.aggsortIPN.splice(
                        postion - 1,
                        0,
                        this.selection.selected[i]
                    );
                    postion = +postion + 1;
                }
                if (this.selectedVal == 'SAN') {
                    if (postion > this.aggsortSAN.length || postion == -1) {
                        postion = this.aggsortSAN.length + 1;
                    }

                    this.aggsortSAN.splice(
                        postion - 1,
                        0,
                        this.selection.selected[i]
                    );
                    postion = +postion + 1;
                }
            }
            // console.log(this.selection.selected);

            if (this.selectedVal == 'ION') {
                this.dataSource = new MatTableDataSource(this.aggsortION);
            } else if (this.selectedVal == 'IPN') {
                this.dataSource = new MatTableDataSource(this.aggsortIPN);
            } else if (this.selectedVal == 'SAN') {
                this.dataSource = new MatTableDataSource(this.aggsortSAN);
            }
            this.selection.clear();
        }
    }

    async loadAgreementList() {
        this.agreementList = await this.http
            .get<Array<DetailedAgreement>>('/api/detailedagreements')
            .toPromise();
        //console.log( this.agreementList?.length);
    }

    //Opens the dialog for adding new agreements
    addnewitem() {
        this.dbvalue = false;

        const userDialogConfig = new MatDialogConfig();

        userDialogConfig.disableClose = true;
        userDialogConfig.autoFocus = true;
        if (this.selectedVal == 'ION') {
            userDialogConfig.data = {
                GPO: this.selectedVal,
                SelectedAgreements: this.aggsortION,
                AgreementList: this.agreementList,
                // SelectedQuarter: this.selectedQuarterCode,
            };
        } else if (this.selectedVal == 'IPN') {
            userDialogConfig.data = {
                GPO: this.selectedVal,
                SelectedAgreements: this.aggsortIPN,
                AgreementList: this.agreementList,
                //SelectedQuarter: this.selectedQuarterCode,
            };
        } else if (this.selectedVal == 'SAN') {
            userDialogConfig.data = {
                GPO: this.selectedVal,
                SelectedAgreements: this.aggsortSAN,
                AgreementList: this.agreementList,
                //SelectedQuarter: this.selectedQuarterCode,
            };
        }
        userDialogConfig.width = '97%';
        userDialogConfig.maxWidth = '99%';

        userDialogConfig.height = '90%';
        this.dialog
            .open(SortadditemComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                var position: number = 0;
                if (saved.data != undefined) {
                    if (
                        this.selectedVal == 'ION' &&
                        (saved.data.Position == undefined ||
                            saved.data.Position == '')
                    ) {
                        position = this.aggsortION.length + 1;
                    } else if (
                        this.selectedVal == 'IPN' &&
                        (saved.data.Position == undefined ||
                            saved.data.Position == '')
                    ) {
                        position = this.aggsortIPN.length + 1;
                    } else if (
                        this.selectedVal == 'SAN' &&
                        (saved.data.Position == undefined ||
                            saved.data.Position == '')
                    ) {
                        position = this.aggsortSAN.length + 1;
                    } else {
                        position = saved.data.Position;
                    }
                    // console.log(saved.data.Position);
                    //console.log(saved.data.SelectedAgreements);
                    this.AddSelectedAgreements(
                        position,
                        saved.data.SelectedAgreements
                    );
                    this.saved = false;
                }
            });
    }
    //Saves the Sort into database
    onsubmit() {
        this.submitted = true;
        this.aggsort = [];

        if (this.aggsortION.length > 0) {
            for (var count = 0; count < this.aggsortION.length; count++) {
                this.aggsortION[count].updateUserId = this.getLoginUserId();
                this.aggsortION[count].agreementTypeCode = null;
                this.aggsortION[count].periodProfileCode = null;
                this.aggsortION[count].levelCode = null;

                this.aggsortION[count].updateDate = new Date();
                this.aggsortION[count].sortPosition = count + 1;
                this.aggsortION[count].quarter =
                    this.quarterCodeFormControl.value;
            }
        }
        if (this.aggsortIPN.length > 0) {
            for (var count = 0; count < this.aggsortIPN.length; count++) {
                this.aggsortIPN[count].updateUserId = this.getLoginUserId();
                this.aggsortIPN[count].agreementTypeCode = null;
                this.aggsortIPN[count].periodProfileCode = null;
                this.aggsortIPN[count].levelCode = null;
                this.aggsortIPN[count].updateDate = new Date();
                this.aggsortIPN[count].sortPosition = count + 1;
                this.aggsortIPN[count].quarter =
                    this.quarterCodeFormControl.value;
            }
        }

        if (this.aggsortSAN.length > 0) {
            for (var count = 0; count < this.aggsortSAN.length; count++) {
                this.aggsortSAN[count].updateUserId = this.getLoginUserId();
                this.aggsortSAN[count].agreementTypeCode = null;
                this.aggsortSAN[count].periodProfileCode = null;
                this.aggsortSAN[count].levelCode = null;
                this.aggsortSAN[count].updateDate = new Date();
                this.aggsortSAN[count].sortPosition = count + 1;
                this.aggsortSAN[count].quarter =
                    this.quarterCodeFormControl.value;
            }
        }

        this.aggsort = this.aggsort
            .concat(this.aggsortION)
            .concat(this.aggsortIPN)
            .concat(this.aggsortSAN);
        if (this.aggsort.length <= 0) {
            return;
        }

        this.scorecardservice
            .SaveSort(this.aggsort)
            .pipe(catchError(this.handleError))
            .subscribe(
                result => {
                    if (result != undefined) {
                        this.openSnackBar('Sort saved successfully');
                        this.selectedVal = 'ION';
                        this.dbvalue = true;
                        this.loadSortList();
                    }
                },
                err => {
                    this.openSnackBar(err);
                }
            );
        this.saved = true;
    }

    getLoginUserId() {
        return clientSession.linkUser?.id ?? '';
    }

    getLoginUserAzureUserName() {
        return clientSession.linkUser?.azureUsername ?? '';
    }

    //Adds the slected item from Add new item dialog to the specified position
    AddSelectedAgreements(postion: number, agreement: DetailedAgreement[]) {
        var length: number = 0;
        this.selectedValOral = 'All';
        if (this.selectedVal == 'ION') {
            length = this.aggsortION.length;
        } else if (this.selectedVal == 'IPN') {
            length = this.aggsortIPN.length;
        } else if (this.selectedVal == 'SAN') {
            length = this.aggsortSAN.length;
        }
        agreement.forEach((agreement: DetailedAgreement) => {
            //var expired: boolean = false;
            //if (agreement.contractExpDate < new Date()) {
            //    expired = true;
            //}
            var agradd: AgreementSort = {} as AgreementSort;

            agradd.agreementId = agreement.agreementId;
            agradd.agreementName = agreement.agreementName;
            agradd.agreementTypeCode = {} as AgreementTypeCode;
            // agradd.agreementTypeDescription = agreement.agreementTypeDescription;
            agradd.agreementTypeCode.agreementType = agreement.agreementType;
            agradd.agreementTypeCode.agreementTypeDescription =
                agreement.agreementTypeDescription;
            agradd.agreementType = agreement.agreementType;
            agradd.drug = agreement.drug;
            agradd.gpo = agreement.gpo;
            agradd.manufacturer = agreement.manufacturer;
            agradd.route = agreement.route;
            agradd.level = agreement.level;
            agradd.levelCode = {} as LevelCode;
            //agradd.levelDescription = agreement.levelDescription;

            agradd.levelCode.level = agreement.level;
            agradd.levelCode.levelDescription = agreement.levelDescription;

            agradd.periodProfileCode = {} as PeriodProfileCode;
            agradd.periodProfileCode.periodProfile = agreement.periodProfile;
            agradd.periodProfileCode.periodProfileDescription =
                agreement.periodProfileDescription;
            agradd.periodProfile = agreement.periodProfile;
            agradd.startDate = agreement.contractStartDate;
            agradd.endDate = agreement.contractExpDate;
            agradd.hidden = agreement.hide;
            //agradd.expired = expired;

            if (this.selectedVal == 'ION') {
                if (length == 0) {
                    this.aggsortION.push(agradd);
                } else {
                    if (postion > this.aggsortION.length + 1) {
                        postion = this.aggsortION.length + 1;
                    }
                    this.aggsortION.splice(postion - 1, 0, agradd);
                    postion = postion + 1;
                }
            } else if (this.selectedVal == 'IPN') {
                if (length == 0) {
                    this.aggsortIPN.push(agradd);
                } else {
                    if (postion > this.aggsortIPN.length + 1) {
                        postion = this.aggsortIPN.length + 1;
                    }
                    this.aggsortIPN.splice(postion - 1, 0, agradd);
                    postion = postion + 1;
                }
            } else if (this.selectedVal == 'SAN') {
                if (length == 0) {
                    this.aggsortSAN.push(agradd);
                } else {
                    if (postion > this.aggsortSAN.length + 1) {
                        postion = this.aggsortSAN.length + 1;
                    }
                    this.aggsortSAN.splice(postion - 1, 0, agradd);
                    postion = postion + 1;
                }
            }
        });
        if (this.selectedVal == 'ION') {
            this.dataSource = new MatTableDataSource(this.aggsortION);
        } else if (this.selectedVal == 'IPN') {
            this.dataSource = new MatTableDataSource(this.aggsortIPN);
        } else if (this.selectedVal == 'SAN') {
            this.dataSource = new MatTableDataSource(this.aggsortSAN);
        }
    }
    //AddRemoveForMoveItems(row: any, event: any) {
    //
    //    if (event.target.checked) {
    //        this.moveitemlist.push(row);
    //    }
    //    else {
    //        if (this.moveitemlist.indexOf(row) >= 0) {
    //            this.moveitemlist.forEach((element, index) => {
    //                if (element == row) this.moveitemlist.splice(index, 1);
    //            });
    //        }
    //    }
    //    console.log(this.moveitemlist);
    //}
    //Delete the agreement from the sort list
    deleteItem(event: any) {
        this.dbvalue = false;
        if (this.selectedVal == 'ION') {
            this.aggsortION.splice(
                this.aggsortION.findIndex(
                    x => parseInt(x.agreementId) === parseInt(event.agreementId)
                ),
                1
            );
            // this.dataSource = new MatTableDataSource(this.aggsortION);
        } else if (this.selectedVal == 'IPN') {
            this.aggsortIPN.splice(
                this.aggsortIPN.findIndex(
                    x => parseInt(x.agreementId) === parseInt(event.agreementId)
                ),
                1
            );
            //this.dataSource = new MatTableDataSource(this.aggsortIPN);
        } else if (this.selectedVal == 'SAN') {
            this.aggsortSAN.splice(
                this.aggsortSAN.findIndex(
                    x => parseInt(x.agreementId) === parseInt(event.agreementId)
                ),
                1
            );
            //this.dataSource = new MatTableDataSource(this.aggsortSAN);
        }
        this.onValChangeOral(this.selectedValOral);
        this.saved = false;
    }

    loadSortList() {
        //this.scorecardservice.loadSort().subscribe(result => {
        this.scorecardservice
            .loadSortlistbyquarter(this.quarterCodeFormControl.value)
            .subscribe(result => {
                for (var i = 0; i < result.length; i++) {
                    if (result[i].levelCode == undefined) {
                        result[i].levelCode = {} as LevelCode;
                        result[i].levelCode!.levelDescription = 'Unknown';
                    }
                    if (result[i].periodProfileCode == undefined) {
                        result[i].periodProfileCode = {} as PeriodProfileCode;
                        result[i].periodProfileCode!.periodProfileDescription =
                            'Unknown';
                    }
                    if (result[i].agreementTypeCode == undefined) {
                        result[i].agreementTypeCode = {} as AgreementTypeCode;
                        result[i].agreementTypeCode!.agreementTypeDescription =
                            'Unknown';
                    }
                }
                // console.log(result);
                this.aggsortION = result.filter(agr => agr.gpo == 'ION');
                this.aggsortIPN = result.filter(agr => agr.gpo == 'IPN');
                this.aggsortSAN = result.filter(agr => agr.gpo == 'SAN');
                if (this.selectedVal == 'ION') {
                    this.dataSource = new MatTableDataSource(this.aggsortION);
                } else if (this.selectedVal == 'IPN') {
                    this.dataSource = new MatTableDataSource(this.aggsortIPN);
                } else if (this.selectedVal == 'SAN') {
                    this.dataSource = new MatTableDataSource(this.aggsortSAN);
                }
                //this.dataSource = new MatTableDataSource(this.aggsortION);
            });
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `,
                error.error
            );
        }
        // Return an observable with a user-facing error message.
        return throwError(
            () => new Error('Something bad happened; please try again later.')
        );
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
        });
    }

    changeSortList(quarter: any) {
        this.submitted = false;
        const currentquarter = this.quarterCode.find(
            obj => obj.quarter === quarter
        );
        if (currentquarter != undefined) {
            this.selectedQuarterCode = currentquarter;
        }
        this.loadSortList();
    }

    //Reorder the sort positions values on togglebutton change
    ReOrderSortPosition() {
        if (this.selectedVal == 'ION') {
            if (this.aggsortION.length > 0) {
                for (var count = 0; count < this.aggsortION.length; count++) {
                    this.aggsortION[count].sortPosition = count + 1;
                }
            }
        } else if (this.selectedVal == 'IPN') {
            if (this.aggsortIPN.length > 0) {
                for (var count = 0; count < this.aggsortIPN.length; count++) {
                    this.aggsortIPN[count].sortPosition = count + 1;
                }
            }
        } else if (this.selectedVal == 'SAN') {
            if (this.aggsortSAN.length > 0) {
                for (var count = 0; count < this.aggsortSAN.length; count++) {
                    this.aggsortSAN[count].sortPosition = count + 1;
                }
            }
        }
    }

    btnExport() {
        //var sortList = this.dataSource.filteredData.forEach(item => item)
        TableUtil.exportArray(
            this.dataSource.filteredData.map(item => item),
            'Sort'
        );
    }
}
