/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SystemConfiguration } from '../../model/SystemConfiguration';

@Component({
    selector: 'app-system-configuration',
    templateUrl: './system-configuration.component.html',
    styleUrls: ['./system-configuration.component.scss'],
})
export class SystemConfigurationComponent implements OnInit {
    public systemConfiguration?: SystemConfiguration[];

    constructor(private http: HttpClient) {}

    ngOnInit() {
        /*this.http
            .get<SystemConfiguration[]>('/api/systemconfiguration')
            .subscribe(
                result => {
                    this.systemConfiguration = result;
                },
                error => console.error(error)
            );*/
    }
}
